'use client';

import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';

import { Button, Heading3, TextSmall } from '@squadnet/frontend-web-ui';

interface ErrorBoundaryProps {
  error: Error;
  reset: () => void;
}

export const ErrorBoundary = ({ error, reset }: ErrorBoundaryProps) => {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <div className='flex flex-col items-center justify-center'>
      <Heading3>Something went wrong</Heading3>
      <TextSmall>{error.message}</TextSmall>
      <Button variant='primary' onClick={reset} className='mt-3'>
        Recover
      </Button>
    </div>
  );
};
